<template>
	<div>
		<div class="footer relative">
			<div class="f-row-1 fr-s-c">
				<div class="link-title fr-c relative">
					<!-- <img src="../assets/imgs/home/link_bg.png" class="w-1 h-1" style="position: absolute; left: 0; top: 0;" > -->
					<span class="relative">{{ $t('m.Links') }}：</span>
				</div>
				<div class="fr-s-c" style="margin-left:35px;">
					<p v-for="(value, key, index) in links" :key="index">
						<a style="font-size: 14px;" :href="value">{{ key }}</a>
						<el-divider direction="vertical" class="v-line"></el-divider>
					</p>
				</div>
			</div>
			<div class="line-out">
				<el-divider class="r-line"></el-divider>
			</div>
			<div class="f-row-2 fr-s-c" v-if="info">
				<div class="fr-s-c">
					<img src="../assets/imgs/home/phone.png" class="icon_img">
					<span>{{ $t('m.Telephone') }}：{{ info[9].value }}</span>
				</div>
				<div class="fr-s-c">
					<img src="../assets/imgs/home/emil.png" class="icon_img">
					<span>{{ $t('m.mailbox') }}：{{ info[8].value }}</span>
				</div>
				<div class="fr-s-c">
					<img src="../assets/imgs/home/address.png" class="icon_img">
					<span>{{ $t('m.address') }}：{{ info[6].value }}</span>
				</div>
			</div>
		</div>
		<div class="to-top-bar fr-c">
			<div style="width: 1200px; margin: 0 auto;" class="fr-b-c">
				<span v-if="info != null">Copyright © 2018 {{ info[0].value }} Inc. All rights reserved. <a
						href="https://beian.miit.gov.cn/" style="text-decoration: none;color: white;"> {{ info[1].value
						}}</a>
					技术支持：威京软件科技</span>
				<div class="to-top-box fr-c relative" @click="toTop">
					<!-- <img src="../assets/imgs/home/totop_bg.png" class="w-1 h-1" style="position: absolute; left: 0; top: -0.5rem;" > -->
					<img src="../assets/imgs/home/to_cop_icon.png" class="relative top-icon">
					<span class="relative">{{ $t('m.Backtotop') }}</span>
				</div>
			</div>
		</div>


		<div class="fr-c f-bottom">
			<img src="../assets/imgs/home/jh.png" class="jh-img">
			<span style="color: #939393; margin-left: 10px;">{{ $t('m.Tianjinpublicnetworksecurity') }}
				12011102000517</span>
		</div>
	</div>
</template>

<script>
import url from "../request/base";
export default {
	name: 'Footer',
	props: {

	},
	data() {
		return {
			info: null,// 接口返回的数组
			links: null,
			imgurl: url.baseUrlimg,
		}
	},
	created() {
		this.getAbout()
	},
	methods: {
		toTop() {
			window.scrollTo(0, 0);
		},
		getAbout() {
			this.$api.home.getAbout({}).then(res => {
				console.log(res, 'anpit')
				this.info = res.data.config
				this.links = JSON.parse(res.data.config[11].value)
			})
		}
	}
}
</script>

<style scoped="scoped">
.footer {
	position: relative;
	min-width: 1400px;
	padding: 20px 0px;
	/* height: 241px; */
	background-color: #333;
	padding-bottom: 70px;
}

.f-bottom {
	min-width: 1400px;
	padding: 0 100px;
	height: 67px;
	margin-top: 15px;
	background-color: #FFFFFF;
}

.jh-img {
	width: 20px;
	height: 20px;
}

.link-title {
	width: 160px;
	height: 41px;
	font-size: 16px;
	color: #FFFFFF;
	background-color: #94C019;
}

.f-row-1 {
	width: 1200px;
	color: #FFFFFF;
	margin: 0 auto;
	background-color: #333;
}

.f-row-1 a {
	color: #FFFFFF;
	font-size: 20px;

}

.v-line {
	margin: 0 30px;
}

.line-out {
	width: 1200px;
	margin: 0 auto;
	padding: 20px 0px;
}

.r-line {
	background-color: #333;
	margin: 0 auto;
	width: 100%;
}

.icon_img {
	width: 30px;
	height: 30px;
}

.f-row-2 {
	width: 1200px;
	margin: 0 auto;
	color: #FFFFFF;
	font-size: 14px;
}

.f-row-2>div {
	margin-right: 50px;
}

.f-row-2>div:last-of-type {
	margin-right: 0;
}

.f-row-2 span {
	margin-left: 11px;
}

.to-top-bar {
	padding: 0 100px;
	font-size: 14px;
	height: 40px;
	min-width: 1400px;
	background: #333;
	color: #FFFFFF;
	margin-top: -55px;
	position: relative;
}

.to-top-box {
	width: 116px;
	height: 53px;
	cursor: pointer;
	font-size: 12px;
}

.top-icon {
	width: 18px;
	height: 30px;
	margin-right: 10px;
}
</style>
