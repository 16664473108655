//zh.js

import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zh = {
    m: {
        // footer
        "price": '产品中心',
        "Links": "友情链接",
        "Telephone": "电话",
        "mailbox": "邮箱",
        "address": "地址",
        "Backtotop": "返回顶部",
        // head
        "Home": "网站首页",
        "CompanyProfile": "公司简介",
        "SalesDepartmentUKbranch": "销售部英国分公司",
        "technicalservice": "技术服务",
        "Latestbroadcast": "最新播报",
        "Companyqualification": "公司资质",
        "contactus": "联系我们",
        "Latestannouncement": "最新公告",
        "Orbitballvalve": "ORBIT轨道球阀",
        // home		
        "Intelligence&Creation": "智能 & 创造",
        "Companyprofile": "公司简介",
        "Qualificationcertification": "资质认证",
        "Safeoperation": "安全作业",
        "Integratedsales": "整合销售",
        "Pastperformance": "以往业绩",
        "contactus": "联系我们",
        "Professionalintegrityoftechnicalservices": "技术服务专业诚信",
        "Latestprojectbroadcast": "最新项目播报",
        "Companyqualification": "公司资质",
        "Viewdetails": "查看详情",
        // about 等页
        "homebar": '首页',
        "text": "正文",
        "list": "列表",
        "Previous": "上一条",
        "Next": "下一条",
        // 分页器
        "previouspage": "上一页",
        "nextpage": "下一页",
        "nothing": "无",
        "Norelevantcontent": "暂无相关内容",
        "Tianjinpublicnetworksecurity": "津公网安备"
    },
    ...zhLocale
}

export default zh