//en.js
import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
    m: {
        // footer
        "price": 'Product Center',
        "Links": "Links",
        "Telephone": "Telephone",
        "mailbox": "mailbox",
        "address": "address",
        "Backtotop": "Backtotop",
        // head
        "Home": "Home",
        "CompanyProfile": "Company Profile",
        "SalesDepartmentUKbranch": "Sales Department UK branch",
        "technicalservice": "technical service",
        "Latestbroadcast": "Latest broadcast",
        "Companyqualification": "Company qualification",
        "contactus": "contact us",
        "Latestannouncement": "Latest announcement",
        "Orbitballvalve": "Orbit ball valve",
        // home

        "Intelligence&Creation": "Intelligence & Creation",
        "Companyprofile": "Company profile",
        "Qualification certification": "Qualification certification",
        "Safeoperation": "Safe operation",
        "Integratedsales": "Integrated sales",
        "Pastperformance": "Past performance",
        "contactus": "contact us",
        "Professionalintegrityoftechnicalservices": "Professional integrity of technical services",
        "Latestprojectbroadcast": "Latest project broadcast",
        "Companyqualification": "Company qualification",
        "Viewdetails": "View details",

        // about 其他页
        "homebar": 'home',
        "text": "text",
        "list": "list",
        "Previous": "Previous",
        "Next": "Next",
        // 分页器
        "previouspage": "previous page",
        "nextpage": "next page",
        "nothing": "nothing",
        "Norelevantcontent": "No relevant content",
        "Tianjinpublicnetworksecurity": "Tianjin public network security"
    },
    ...enLocale
}

export default en